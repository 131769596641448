<template>
  <div>
    设计前沿
  </div>
</template>

<script>
export default {
  name: "designFrontier"
}
</script>

<style scoped>

</style>
